.briefing.-keywords_alerts {
    gap: var(--spacing-s);
}

.briefing_keywords_alerts__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 var(--spacing-xl) 0;
}

.briefing_keywords_alerts__subtitle{
    padding: var(--spacing-l) 0;
}

.empty__message{
    padding: 0 0 var(--spacing-xl) 0;
    .empty__title{
        padding: var(--spacing-s) 0;
        gap: var(--spacing-xxl);
    }
}
