.menu {
  background-color: var(--color-neutral-surface-contrast-dark);
  width: 32.8rem;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  border: none;
  overflow-y: auto;
  transition: transform 0.3s, visibility 0s 0.3s;

  &.-hidden {
    visibility: hidden;
    transform: translateX(-100%);
    pointer-events: none;
  }
}

.menu__close-button {
  display: flex;
  padding: var(--spacing-xxs) var(--spacing-xl);
  align-items: flex-start;
  gap: var(--spacing-m);
  align-self: stretch;

  & > button {
    height: 4rem !important;
    width: 4rem !important;
    padding: var(--spacing-xxs) var(--spacing-s) !important;
    color: var(--color-neutral-text-shape-contrast-default);
    border-radius: var(--radius-max);

    > svg {
      width: 2.4rem;
      height: 2.4rem;
    }

  }
}

.menu__wrap {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-s) var(--spacing-xxl);
  gap: var(--spacing-xl);
}

@mixin menu-button {
  display: flex;
  align-items: center;
  background-color: var(--color-neutral-surface-contrast-dark);
  border-radius: var(--radius-min);
  color: var(--color-neutral-text-shape-contrast-default);
  border: 1px solid var(--color-neutral-border-contrast-default);
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:focus-visible {
    background-color: var(--color-global-state-hover-surface-default);
    border-color: var(--color-global-state-hover-border-default);
  }

  &:active {
    background-color: var(--color-global-state-hover-surface-dark);
    border-color: var(--color-global-state-hover-surface-dark) !important;
  }
}

.menu__today-briefing {
  @include menu-button;

  flex-direction: row;
  justify-content: space-between;
  padding: var(--spacing-l) var(--spacing-xl);
}

.menu__today-briefing-icon {
  width: 2.4rem;
  height: 2.4rem;
}

.menu__today-briefing-text {
  @include font-produkt-xl;
  text-decoration: none;
  text-transform: none;

  .-briefings {
    color: var(--color-brand-primary-text-shape-default);
  }
}

.menu__today-briefing:hover .menu__today-briefing-text,
.menu__today-briefing:active .menu__today-briefing-text,
.menu__today-briefing:focus-visible .menu__today-briefing-text {
  text-decoration: none;

  .-briefings {
    color: var(--color-global-white);
  }
}

.button.open-modal-button {
  @include menu-button;
  @include font-interface-m-semi;

  justify-content: flex-start;
  width: 100%;
}

.menu__submenu {
  @include font-condensed-m-strong;
  display: flex;
  flex-direction: column;
  width: 100%;

  &.-footer-links {
    margin: var(--spacing-xxxxl) var(--spacing-none);
    padding-top: var(--spacing-s);
    gap: var(--spacing-s);
  }
}

.menu__submenu-title {
  @include font-condensed-m-strong;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  color: var(--color-neutral-text-shape-contrast-light);
  height: 4rem;
}

.menu__submenu-items {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: var(--spacing-s) var(--spacing-none) var(--spacing-none);
  margin: var(--spacing-none);
  border-top: 1px solid var(--color-neutral-border-contrast-default);
  gap: var(--spacing-s);
  list-style: none;

  &.-editions {
    border: none;
    gap: var(--spacing-none);
    padding: var(--spacing-none)
  }
}

.menu__submenu-item {
  display: flex;
  align-items: center;
  height: 2.5rem;

  &:has(.premium-flag) {
    justify-content: space-between;
  }

  &:has(svg) {
    gap: var(--spacing-s);
  }

  svg {
    width: 2.9rem;
    height: 3.9rem;
  }
}

.menu__submenu-tool, .menu__edition-tag, .menu__submenu-text {
  @include font-sans-m-default;
  color: var(--color-neutral-text-shape-contrast-default);
  display: block;
  text-decoration: none;
  text-transform: none;

  &.-focus-event{
    padding: var(--spacing-s) 0;
  }
}

.menu__edition-container {
  display: flex;
  padding: var(--spacing-s) var(--spacing-none) var(--spacing-none);
  margin: var(--spacing-xxxs) var(--spacing-xxxs) var(--spacing-xxl) var(--spacing-xxxs);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-s);
  list-style: none;
}

.menu__edition-name {
  @include font-edition-xl;
  color: var(--color-neutral-text-shape-contrast-default);
  text-decoration: none;
}

details > summary::-webkit-details-marker {
  display: none;
}

details > summary {
  list-style-type: none;
}

.menu__edition {
  display: flex;
  border-top: 1px solid var(--color-neutral-border-contrast-default);
  padding: var(--spacing-s) var(--spacing-none);
  flex-wrap: wrap;
}

.menu__edition-name-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: var(--spacing-xxxs);
}

.menu__edition-name-wrap:hover {
  cursor: pointer;
  text-decoration: none;
}

.menu__edition-name-wrap:hover .menu__submenu-chevron,
.menu__edition-name-wrap:focus-visible .menu__submenu-chevron {
  color: var(--color-global-state-hover-text-shape-contrast-default);
}

.menu__edition-name-wrap:active .menu__submenu-chevron {
  color: var(--color-global-state-hover-text-shape-inverted);
}

.menu__edition-name-wrap:active .menu__edition-name {
  color: var(--color-global-state-hover-text-shape-inverted);
}

.menu__edition-name:hover {
  color: var(--color-global-state-hover-text-shape-contrast-default);
}

.menu__edition-name:hover + .menu__submenu-chevron {
  color: var(--color-neutral-text-shape-contrast-light);
}

.menu__submenu-chevron {
  width: 2.4rem;
  height: 2.4rem;
  color: var(--color-neutral-text-shape-contrast-light);
  transition: transform 0.1s ease-out;
  transform: rotate(180deg);
}

details[open] .menu__submenu-chevron {
  transform: rotate(0);
}

.menu__edition-link {
  @include font-sans-m-link;
  color: var(--color-neutral-text-shape-contrast-light);
  text-transform: none;
}

.menu__submenu-tool:hover, .menu__edition-tag:hover, .menu__edition-link:hover, .menu__submenu-text:hover {
  color: var(--color-global-state-hover-text-shape-contrast-default);
}

.menu__user-settings {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l);
}

@media (max-width: $breakpoint-mobile) {
  .menu {
    width: 100%;
    max-width: unset;
  }

  .menu__edition-name, .menu__today-briefing-text {
    font-size: 2rem;
  }

  .menu__submenu.-footer-links {
    margin: var(--spacing-xxxxl) var(--spacing-none) 12rem var(--spacing-none);
  }

  .menu__edition-name-wrap:hover .menu__submenu-chevron {
    color: var(--color-neutral-text-shape-light);
  }
}
