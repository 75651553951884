.premium-flag {
  display: inline-flex;
  padding: var(--spacing-xs) var(--spacing-xxs);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-xxs);

  border-radius: var(--radius-min);
  background: var(--color-brand-abo-surface-default);
  color: var(--color-brand-abo-text-shape-darker);

  &:has(svg) {
    padding: var(--spacing-xs) var(--spacing-s) var(--spacing-xs) var(--spacing-xs);
  }

  &.-contrasted {
    color: var(--color-brand-abo-text-shape-contrast-dark);
    background: var(--color-brand-abo-surface-contrast-lighter);
  }
}

.premium-flag__icon {
  display: flex;
  width: 1.4rem;
  height: 1.4rem;
  padding-bottom: var(--spacing-xxxs);
}

.premium-flag__content {
  @include font-interface-xxs-semi;
  letter-spacing: 0.055rem;
  text-transform: uppercase;
}

.premium__link {
  @include font-sans-m-semi-link;
  color: var(--color-brand-abo-text-shape-dark);

  &:hover {
    color: var(--color-global-state-hover-text-shape-default);
  }
}
