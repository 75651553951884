.radio__label {
  display: flex;
  align-items: center;
  color: var(--color-neutral-text-shape-default);

  & input[type="radio"] {
    flex-shrink: 0;
  }

  &:has(input[type="radio"]:disabled) {
    color: var(--color-global-state-disabled-text-shape-default);
    pointer-events: none;

    &.-contrasted {
      color: var(--color-global-state-disabled-text-shape-contrasted);

      & input[type="radio"] {
        background-color: var(--color-global-state-disabled-surface-contrasted);

        &:checked {
          background-color: var(--color-global-state-disabled-text-shape-contrasted);
          border-color: var(--color-global-state-disabled-surface-contrasted);
        }
      }
    }
  }

  /* Skin */

  &.-contrasted {
    color: var(--color-neutral-text-shape-contrast-default);

    & input[type="radio"] {
      outline-color: var(--color-neutral-border-contrast-darker);

      &:hover,
      &:focus-visible {
        background-color: var(--color-neutral-surface-contrast-dark);
        outline-color: var(--color-global-state-hover-border-dark);
      }

      &:checked {
        border-color: var(--color-neutral-surface-contrast-light);
        outline-color: var(--color-global-state-hover-border-default);

        &:hover,
        &:focus-visible {
          background-color: var(--color-global-state-hover-border-dark);
          outline-color: var(--color-global-state-hover-border-dark);
        }
      }
    }

    &:has(input[type="radio"]:disabled) {
      color: var(--color-global-state-disabled-text-shape-contrasted);
    }
  }

  /* Sizes */

  &.-size-l {
    @include font-sans-l-default;


    // outline property is outside so we have to remove it from width and height
    & input[type="radio"] {
      // 2.4 (total size) - 0.1 (outline) * 2
      width: 2.2rem;
      height: 2.2rem;
      margin: 0 var(--spacing-l) 0 0;
      &:checked {
        border-width: 0.5rem;
      }
    }
  }

  &.-size-m {
    @include font-sans-m-default;

    & input[type="radio"] {
      width: 1.8rem;
      height: 1.8rem;
      margin: 0 var(--spacing-m) 0 0;
      &:checked {
        border-width: 0.4rem;
      }
    }
  }

  &.-size-s {
    @include font-sans-s-default;

    & input[type="radio"] {
      width: 1.4rem;
      height: 1.4rem;
      margin: 0 var(--spacing-s) 0 0;
      &:checked {
        border-width: 0.3rem;
      }
    }
  }

  /* States */

  &,
  & input {
    cursor: pointer;
  }
}

input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  border-radius: var(--radius-max);
  /* The outline will be the outer circle */
  outline: 1px solid var(--color-neutral-border-default);

  /* States */

  &:hover,
  &:focus-visible {
    outline: 1px solid var(--color-global-state-hover-border-dark);
    background-color: var(--color-neutral-surface-default);
  }

  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
    background: var(--color-global-state-disabled-surface-default);
    outline: 0;
  }

  &:checked {
    background-color: var(--color-global-state-hover-border-default);
    border-style: solid;
    border-color: var(--color-neutral-surface-contrast-light);
    outline: 1px solid var(--color-global-state-hover-border-default);

    &:disabled {
      background-color: var(--color-global-state-disabled-text-shape-default);
      border-color: var(--color-neutral-surface-contrast-light);
      outline: 0;
    }

    &:hover,
    &:focus-visible {
      background-color: var(--color-global-state-hover-border-dark);
      border-color: var(--color-neutral-surface-contrast-default);
      outline-color: var(--color-global-state-hover-border-dark);
    }
  }
}
