@mixin section {
  display: flex;
  padding: var(--spacing-xxl);
  background: var(--color-neutral-surface-lighter);
  border: solid 1px var(--color-neutral-border-light);
  border-radius: var(--radius-rounded);
  align-items: center;
  flex-direction: column;
}

.author-content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxxl);
}

.author-presentation {
  @include section;
}

.author-presentation__content, .author-doi__content {
  max-width: 64rem;
}

.author-box {
  display: flex;
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-xl);

  &.-column {
    display: flex;
    gap: 0;
    padding: 0 var(--spacing-xxs);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

.author-presentation__avatar {
  border: 1px solid var(--color-neutral-border-light);
  border-radius: 50%;
  display: inline-block;
  height: 8.9rem;
  width: 8.9rem;
  overflow: hidden;
  position: relative;
}

.author-presentation__avatar-img {
  object-fit: cover;
  width: 8.9rem;
  height: 8.9rem;
}

.author-presentation__name {
  @include font-sans-xl-strong;
  color: var(--color-neutral-text-shape-default);
}

.author-presentation__role {
  @include font-sans-m-default;
  color: var(--color-neutral-text-shape-light);
}

.author-presentation__biography {
  @include font-sans-m-default;
  color: var(--color-neutral-text-shape-default);
  margin-top: var(--spacing-s);
}

.author-presentation__contacts {
  margin-top: var(--spacing-xxl);
  display: flex;
  width: 100%;
  justify-content: flex-start;
  gap: var(--spacing-xl);
  flex-wrap: wrap;
}

.author-presentation__button-container {
  text-decoration: none;
  color: var(--color-neutral-text-shape-default);
}

:root.ctx-light-mode {
  .button.-secondary.-square.-linkedin:not(:hover, :active) {
    background-color: var(--color-global-social-linkedin);
    border-color: var(--color-global-social-linkedin);
    color: var(--color-global-white)
  }

  .button.-secondary.-square.-twitter:not(:hover, :active) {
    background-color: var(--color-global-social-twitter);
    border-color: var(--color-global-social-twitter);
    color: var(--color-global-white)
  }

  .button.-secondary.-square.-bluesky:not(:hover, :active) {
    color: var(--color-global-social-bluesky);
  }
}


.author-doi {
  @include section;
  align-items: flex-start;
}

.author-doi-title {
  @include font-sans-xl-strong;
  color: var(--color-neutral-text-shape-default);
}

.author-doi__button {
  margin-top: var(--spacing-xxxl);
}

.author-doi__item {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);
  margin-top: var(--spacing-xxl);

  &.-first {
    margin-top: var(--spacing-xl);
  }

  &.-asterisk {
    @include font-sans-xs-default;
    color: var(--color-neutral-text-shape-default);
  }
}

.author-doi__question {
  @include font-sans-s-semi;
  color: var(--color-neutral-text-shape-light);
}

.author-doi__answer {
  @include font-sans-s-default;
  color: var(--color-neutral-text-shape-default);
}

details[open] .author-doi__button {
  display: none;
}

.author-articles {
  display: flex;
  flex-direction: column;
  padding-bottom: var(--spacing-xl);

  & .editorials {
    --editorial-margin: var(--spacing-xxl);
  }
}

.author-articles__title {
  @include font-condensed-l-strong;
  color: var(--color-neutral-text-shape-default);
  margin-bottom: var(--spacing-xxl);
}

.author-articles__container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxxl);
}

@media (max-width: $breakpoint-m) {
  .author-presentation {
    padding: var(--spacing-xl);
  }

  .author-box {
    gap: var(--spacing-s);
  }

  .author-presentation__name {
    @include font-sans-m-strong;
  }

  .author-presentation__role {
    @include font-sans-xs-default;
  }

  .author-presentation__avatar, .author-presentation__avatar-img {
    height: 4.8rem;
    width: 4.8rem;
  }

  .author-presentation__biography {
    @include font-sans-xs-default;
  }

  .author-presentation__contacts {
    margin-top: var(--spacing-xl);
    gap: var(--spacing-s);
  }

  .author-content {
    gap: var(--spacing-xxl);
  }

  .author-doi-title {
    @include font-sans-l-strong;
  }

  .author-doi__button svg {
    width: 1.4rem;
  }

  .author-doi__item {
    margin-top: var(--spacing-xl);

    &.-first {
      margin-top: var(--spacing-m);
    }
  }

  .author-doi {
    padding: var(--spacing-xl);
  }

  .author-doi__question {
    @include font-sans-xs-semi;
  }

  .author-doi__answer {
    @include font-sans-xs-default;
  }

  .author-doi__button {
    @include font-interface-xxs-semi;
    margin-top: var(--spacing-xxl);
  }

  .author-articles .editorials {
    --editorial-margin: var(--spacing-xl);
  }

  .author-articles__title {
    margin-bottom: var(--spacing-xl);
  }

  .author-articles__container {
    gap: var(--spacing-xxl);
  }
}

@media (max-width: $breakpoint-s) {
  .author-content {
    gap: var(--spacing-xl);
  }

}

@media (max-width: $breakpoint-mobile) {
  .author-presentation, .author-doi {
    padding: 0;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid var(--color-neutral-border-light);
    padding-bottom: var(--spacing-xl);
    background: none;
  }

  .author-presentation__contacts {
    gap: var(--spacing-s);
  }

  .author-doi__button {
    margin-top: var(--spacing-xl);
  }

  .author-articles {
    padding: 0;
  }
}
