.newscard__tag-list {
  padding: var(--spacing-m) var(--spacing-none);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-top: 1px solid var(--color-neutral-border-light);
}

.newscard__article-tags {
  padding-right: var(--spacing-xxl);

  display: flex;
  align-items: center;
  align-content: center;
  gap: var(--spacing-xxxs) var(--spacing-xxs);
  flex-wrap: wrap;

  a {
    color: var(--color-neutral-text-shape-light);
    @include font-sans-s-default;

    text-decoration: none;

    &:hover {
      color: var(--color-global-state-hover-surface-default);
    }

    &.-focus {
      color: var(--color-neutral-text-shape-default);

      &:hover {
        color: var(--color-global-state-hover-surface-default);
      }
    }
  }
}

.newscard__article-tag-sep {
  leading-trim: both;
  text-edge: cap;

  color: var(--color-neutral-text-shape-light);
  @include font-interface-s-semi;
}
