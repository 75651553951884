/* Detail */

:root {
  --video-width: 400px;
  --timecode-width: 6.3rem;
}

.layout-main.-transcript {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: $breakpoint-l;
  margin: 0 auto;
}

.transcript-grid {
  display: grid;
  grid-template-columns: var(--video-width) 1fr;
  column-gap: var(--spacing-xxxl);
  width: 100%;
  align-items: start;
  padding-inline: var(--spacing-xl);
}

#transcript-header {
  background-color: var(--color-global-background-light);
}

.transcript-navigation {
  position: sticky;
  top: var(--spacing-48);
}

.transcript-navigation__back-url {
  width: 100%;
  position: sticky;
  top: var(--spacing-48);
  padding: var(--spacing-xl);
  background: var(--color-global-background-light);
  cursor: pointer;
  z-index: 4;

  svg {
    width: 1.6rem;
    height: 1.6rem;
  }

  a {
    @include font-sans-s-default;
    display: flex;
    align-items: center;
    gap: var(--spacing-xxs);
    text-decoration-line: none;
    color: var(--color-neutral-text-shape-default);
  }

  a:hover,
  a:active {
    text-decoration-line: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 2.5px;
    text-decoration-skip-ink: none;
  }

  a:hover,
  a:active {
    color: var(--color-global-state-hover-text-shape-default);
  }
}

.transcript-toc,
#transcript-filters {
  padding-inline: var(--spacing-s);
}

.transcript-toc h2 {
  margin-left: var(--spacing-s);
}

.transcript-navigation {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-bottom: var(--spacing-xxxxl);
  position: sticky;
  top: calc(var(--spacing-96) + var(--spacing-s));
  padding-bottom: calc(var(--spacing-96) + var(--spacing-s));
}

.transcript-video {
  width: 100%;
  aspect-ratio: 16 / 9;
}

.transcript-toc {
  margin-top: var(--spacing-xxl);
  background: var(--color-global-background-light);
  overflow: hidden;
  overflow-y: scroll;
  margin-bottom: var(--spacing-xxl);

  ul {
    list-style-type: none;
    padding: 0;
    margin-top: var(--spacing-xxs);
    margin-bottom: 0;
  }

  li {
    @include font-sans-s-semi;
    padding-left: var(--spacing-s);
    padding-block: var(--spacing-xs);
    border: 1px solid transparent;

    &:hover,
    &.-active {
      color: var(--color-global-state-hover-text-shape-default);
      background: var(--color-global-background-lighter);
      box-shadow: 0 0 10px 0 #0000000A;

      .transcript-toc__length {
        color: var(--color-global-state-hover-text-shape-default);
      }
    }
  }
}

.transcript-toc__length {
  @include font-sans-xs-default;
  color: var(--color-neutral-text-shape-light);
}

.transcript-title {
  @include font-condensed-m-strong;
  color: var(--color-neutral-text-shape-light);
}

.transcript-metadata {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);

  .transcript-metadata__title {
    @include font-sans-xxl-strong;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
}

.transcript-metadata-entities {
  @include font-condensed-m-strong;
}

.transcript-metadata-geography {
  color: var(--color-brand-primary-text-shape-default);
}

.transcript-metadata-institution {
  color: var(--color-neutral-text-shape-light);
}

.transcript-metadata-details {
  @include font-sans-s-default;
  color: var(--color-neutral-text-shape-light);
}

#transcript-filters {
  padding-bottom: var(--spacing-xl);
  background: var(--color-global-background-light);
}

.transcript-filters__separator {
  border-bottom: 1px solid var(--color-neutral-border-lighter);
  margin-top: var(--spacing-xxl);
}

.transcript-filters-container {
  position: sticky;
  top: var(--spacing-88);
  padding: 0 0 var(--spacing-xl);
  background-color: var(--color-global-background-light);
  display: flex;
  flex-direction: column;
  z-index: 2;
}

.transcript-filters__elements {
  display: flex;
  gap: var(--spacing-xl);
  padding-top: var(--spacing-xl);
}

.transcript-search-results {
  position: sticky;
  top: calc(2* var(--spacing-80) + var(--spacing-s));
  display: flex;
  align-items: center;
  gap: var(--spacing-l);
  padding-bottom: var(--spacing-xl);
  border-bottom: 1px solid var(--color-neutral-border-lighter);
  background-color: var(--color-global-background-light);
  width: 100%;
  overflow: hidden;

  &.-empty {
    padding-bottom: 0;
  }
}

.transcript-search-results__navigation {
  display: flex;
  gap: var(--spacing-xxs);
}

.transcript-search-results__icon.-left {
  transform: rotate(180deg);
}

.transcript-search-results__count {
  @include font-interface-m-semi;
  color: var(--color-neutral-text-shape-light);
}

.transcript-filters__filter.-search {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  flex: 2;
  position: relative;

  .transcript-filter__search-icon,
  input {
    grid-column: 1;
    grid-row: 1;
  }

  .transcript-filter__search-icon {
    width: 2rem;
    height: 2rem;
    margin-left: var(--spacing-m);
    color: var(--color-neutral-text-shape-light);
    z-index: 3;
  }

  input {
    padding-block: var(--spacing-m);
    padding-left: var(--spacing-xxxxl);
  }

  button {
    position: absolute;
    right: var(--spacing-m);
    top: 50%;
    transform: translateY(-50%);
    right: var(--spacing-xxs);
  }

  input:placeholder-shown + button {
    display: none;
  }
}

.button.-size-xxs.-square.-disabled svg {
  color: var(--color-global-state-disabled-text-shape-default);
}

.transcript-filters__filter .dropdown__body {
  max-height: calc(100vh / 3);
  overflow: hidden;
  overflow-y: scroll;
}

.transcript-paragraphs {
  overflow: hidden;
  padding-bottom: var(--spacing-xxl);
  padding-top: var(--spacing-xl);
}

.transcript-paragraphs-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
}

.transcript-toc li,
.transcript-paragraph {
  &:hover {
    cursor: pointer;

    .transcript__timecode::before {
      color: var(--color-global-state-hover-text-shape-default);
    }
  }
}

.transcript-paragraph.-active,
.transcript-paragraph:active {
  cursor: pointer;

  .transcript__timecode::before,
  .transcript-paragraph__text {
    color: var(--color-global-state-hover-text-shape-dark);
  }
}

.transcript-paragraph {
  display: grid;
  grid-template-columns: var(--timecode-width) .4rem 1fr;

  &.-inactive {
    color: var(--color-neutral-text-shape-light);
  }

  &:hover {
    cursor: pointer;

    .transcript__timecode::before,
    .transcript-paragraph__text {
      color: var(--color-global-state-hover-text-shape-default);
    }
  }

  &.-scrolling-into .transcript-paragraph__text mark.-highlighted {
    box-shadow: 0px 0px 4px 2px rgba(5, 61, 206, 0.31);
  }
}

.transcript-paragraph__speaker {
  @include font-sans-m-default;
  display: inline-block;
  color: var(--color-feedback-info-text-shape-default);
  margin-left: calc(var(--timecode-width) + var(--spacing-s));
  border-radius: var(--radius-min);

  &:not(:first-child) {
    margin-top: var(--spacing-xl);
  }

  &.-inactive {
    color: var(--color-neutral-text-shape-light);
  }
}

.transcript__timecode::before {
  content: attr(data-timecode);
  @include font-sans-s-default;
  width: fit-content;
  height: fit-content;
  color: var(--color-neutral-text-shape-light);
  margin: var(--spacing-xxxxs) var(--spacing-s) 0 0;
}

.transcript-paragraph__content {
  margin-left: var(--spacing-s);
  padding-inline: var(--spacing-xxs);
  transition: background-color 0.5s ease;

  &.-highlighted {
    background-color: var(--color-global-post-it-surface-light);
    color: var(--color-global-post-it-text-shape-light);
    border-radius: var(--radius-min);
  }
}

.transcript-paragraph__vertical-bar {
  width: 2px;
  background: var(--color-global-background-default);
  margin: var(--spacing-xxs) 0 var(--spacing-xxs) var(--spacing-xxxs);
}

.transcript-paragraph__text {
  @include font-sans-m-default;
}

@media (max-width: $breakpoint-m) {
  :root {
    --video-width: 290px;
  }

  .transcript-grid {
    column-gap: var(--spacing-xxl);
  }

  .transcript-filters-container {
    padding-top: var(--spacing-l);
  }

  .transcript-filters__elements {
    padding-top: var(--spacing-xxs);
  }

  .transcript-toc {
    h2 {
      margin-left: 0;
    }

    li {
      padding-left: 0;
    }
  }

  .transcript-header {
    margin-bottom: 0;
  }

  .transcript-metadata {
    gap: var(--spacing-xxs);
  }
}


@media (max-width: $breakpoint-s) {
  :root {
    --video-width: 170px;
  }

  .transcript-navigation__back-url {
    a {
      @include font-sans-xs-default;
    }

    svg {
      width: 1.4rem;
      height: 1.4rem;
    }
  }

  .transcript-metadata {
    gap: var(--spacing-xxxs);
  }

  .transcript-filters-container {
    flex-direction: column;
    gap: var(--spacing-s);
  }

  .transcript-search-results {
    gap: var(--spacing-s);
  }

  .transcript-search-results__count {
    @include font-interface-s-semi;
    color: var(--color-neutral-text-shape-light);
  }
}

@media (max-width: $breakpoint-mobile) {
  :root {
    --video-height: calc(100vw * 9 / 16);
  }

  .transcript-grid {
    grid-template-columns: 1fr;

    .transcript-metadata-details {
      margin-top: var(--spacing-xxs);
    }

    .transcripts-disclaimer {
      margin-top: var(--spacing-m);
    }
  }

  .transcript-filters__separator {
    display: none;
  }

  .transcript-filters-container {
    top: calc(var(--video-height) + var(--spacing-64) + var(--spacing-xs));
  }

  .transcript-filters__elements {
    border-top: none;
    padding-top: 0;
  }

  .transcript-search-results {
    top: calc(var(--video-height) + var(--spacing-88) + var(--spacing-48) + var(--spacing-xs));
    margin-bottom: var(--spacing-m);

    &.-empty {
      border-bottom: none;
      margin-bottom: 0;
    }
  }

  .transcript-navigation {
    height: auto;
    position: sticky;
    top: var(--spacing-72);
    z-index: 3;
    padding: var(--spacing-xl) 0 0;
    background: var(--color-global-background-light);
  }

  .transcript-navigation__back-url {
    padding-block: var(--spacing-m);
    margin-block: 0;
  }

  .transcript-filters-container {
    padding-top: var(--spacing-s);
    border-top: none;
  }

  .transcript-metadata {
    gap: 0;
    padding-bottom: 0;
  }

  .transcript-metadata__title {
    margin-top: var(--spacing-xxxs);
    margin-bottom: var(--spacing-s);
  }

  .transcript-metadata-entities {
    @include font-condensed-s-strong;
  }

  .transcript-metadata-details {
    @include font-interface-xxs-default;
  }

  .transcript-header {
    padding: 0;
  }

  .transcript-paragraphs {
    padding-top: 0;
    padding-inline: var(--spacing-xxxs);
  }

  .transcript-paragraph {
    display: flex;
    flex-direction: column;
  }

  .transcript-filters__filter .dropdown__body {
    max-height: calc(100vh / 4);
  }

  #transcript-filters {
    border-top: none;
    position: unset;
  }

  .transcript-paragraph__speaker {
    margin-left: 0;

    &:not(:first-child) {
      margin-top: var(--spacing-s);
    }
  }

  .transcript-paragraph__vertical-bar {
    display: none;
  }

  .transcript-paragraph__content {
    margin-left: 0;
    padding-inline: 0;
    padding-top: var(--spacing-s);
  }
}

/* List */

.transcripts {
  width: 100%;
  max-width: 67.5rem;
  margin: 0 auto;
  padding: var(--spacing-xxl);
}

.transcripts-name {
  @include font-sans-xxl-strong;
  display: flex;
  gap: var(--spacing-m);

  svg {
    width: 4rem;
    height: 2.2rem;
    align-self: center;
  }
}

.transcripts-intro {
  @include font-sans-m-semi;
  margin-top: var(--spacing-xl);
  margin-bottom: var(--spacing-xxl);
  background: var(--color-neutral-surface-dark);
  color: var(--color-neutral-text-shape-inverted);
  padding: var(--spacing-xl) var(--spacing-l);
  border-radius: var(--radius-rounded);
}

.transcripts-search {
  display: flex;
  flex-direction: column;
  margin-top: var(--spacing-l);
  margin-bottom: var(--spacing-xl);
  gap: var(--spacing-s);
}

.transcripts-filters {
  display: flex;
  gap: var(--spacing-s);
  margin-bottom: var(--spacing-xxl);
  min-height: 2.6rem;
}

.transcripts-filter__reset {
  margin-left: auto;
}

.transcripts-filters__container {
  display: flex;
  align-items: baseline;
  gap: var(--spacing-s);
  flex-grow: 1;
}

.transcripts-filters__filter {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);

  button {
    padding-inline: var(--spacing-s);
  }
}

.transcripts-filters__link {
  @include font-interface-m-semi;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    color: var(--color-global-state-hover-text-shape-default);
  }
}

.transcripts-filters__container .dropdown__body {
  top: 28px;
  border: 1px solid var(--color-neutral-border-light);
  border-radius: var(--radius-min);
  box-shadow: 0px 0px var(--spacing-xs, 6px) 0px rgba(7, 0, 46, 0.15);
}

.transcripts-filters__option {
  background: var(--color-global-background-light);
  white-space: nowrap;

  label {
    cursor: pointer;
    display: block;
    width: 100%;
    padding: var(--spacing-m) var(--spacing-l);
  }

  input {
    display: none;
  }

  &:not(:first-child) {
    border-top: 1px solid var(--color-neutral-border-light);
  }

  &:hover {
    background: var(--color-global-state-hover-surface-default);
    color: var(--color-global-white);

    .transcripts-filters__results {
      color: var(--color-global-white);
    }

    &[disabled] {
      background: var(--color-global-background-light);
    }
  }

  &[disabled] {
    color: var(--color-global-state-disabled-text-shape-default);

    label {
      cursor: not-allowed;
    }

    .transcripts-filters__results {
      color: var(--color-global-state-disabled-text-shape-default);
    }
  }

  &.-active {
    color: var(--color-global-state-hover-text-shape-default);
    background-color: var(--color-global-state-hover-surface-light);

    .transcripts-filters__results {
      color: var(--color-global-state-hover-text-shape-default);
    }
  }
}

.transcripts-filters__results {
  @include font-sans-s-default;
  color: var(--color-neutral-text-shape-light);
}

.transcripts-filters__datepicker {
  @include font-interface-s-semi;
  border: 1px solid var(--color-neutral-border-light);
  border-radius: var(--radius-min);
  padding: var(--spacing-xxxs) var(--spacing-xs);
  cursor: pointer;
  background: var(--color-neutral-surface-light);
  color: var(--color-neutral-text-shape-dark);
  -webkit-text-fill-color: var(--color-neutral-text-shape-dark);
}

.transcripts-filters__container .dropdown__body {
  min-width: -webkit-fill-available;
  top: 27px;
  background: var(--color-global-background-light);
  border: 1px solid var(--color-neutral-border-light);
  border-radius: var(--radius-min);

  a {
    display: block;
    text-decoration: none;
    padding: var(--spacing-xs) var(--spacing-l);
    white-space: nowrap;
    cursor: pointer;

    &:not(:first-child) {
      border-top: 1px solid var(--color-neutral-border-light);
    }

    &:hover {
      background: var(--color-global-state-hover-surface-default);
      color: var(--color-global-white);
    }
  }
}

#transcripts-results .emptystate a:hover {
  text-decoration-thickness: 0.2rem;
}

@keyframes loader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#transcripts-results_htmx-indicator {
  display: none;
}

#transcripts-results__htmx-indicator.htmx-request {
  display: unset;

  &::after {
    animation: loader 1.1s infinite linear;
    content: url("/home/runner/work/contexte/contexte/apps/transcripts/static_src/transcripts/../../../ui/static_src/ui/base/images/spinner.svg");
    display: block;
    height: 5rem;
    margin: 0 auto;
    width: 5rem;
  }

  + #transcripts-results__container {
    display: none;
  }
}

.transcripts__data-sentence {
  @include font-condensed-l-strong;
  margin-bottom: var(--spacing-s);
}

.transcripts .transcript-paragraph__empty-text {
  padding-inline: 0;
}

.transcripts-disclaimer {
  @include font-interface-xxs-default;
  display: flex;
  align-items: center;
  gap: var(--spacing-xxs);
  width: fit-content;
  background-color: var(--color-global-ia-surface-light);
  color: var(--color-global-ia-text-shape-default);
  line-height: 1.3;
  padding: var(--spacing-xxs);
  border-radius: var(--radius-min);
  margin-top: var(--spacing-xxs);

  svg {
    width: 1.4rem;
    height: 1.4rem;
    flex-shrink: 0;
  }
}

.transcripts-list {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxxl);
  margin-top: var(--spacing-xl);
  margin-bottom: var(--spacing-xxl);

  a {
    text-decoration: none;

    &:hover {
      color: var(--color-global-state-hover-text-shape-default);
    }
  }
}

.transcripts-content {
  display: flex;
  gap: var(--spacing-xl);

  .transcript-metadata {
    gap: var(--spacing-xxs);
    padding: 0;
  }

  .transcript-metadata__title {
    @include font-sans-l-strong;
  }

  .transcript-metadata-details {
    @include font-sans-xs-default;
    margin-top: auto;
  }
}

.transcripts-image {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  min-width: 176px;
  max-width: 176px;
  height: 117px;
  width: 100%;

  :nth-child(1),
  :nth-child(2) {
    grid-column: 1;
    grid-row: 1;
  }

  img {
    object-fit: cover;
    height: 100%;
    max-height: 117px;
    max-width: 176px;
  }

  span {
    @include font-condensed-s-strong;
    width: 6.5rem;
    align-self: end;
    justify-self: end;
    text-align: center;
    background: var(--color-neutral-surface-contrast-dark);
    color: var(--color-neutral-text-shape-contrast-default);
    border-radius: var(--radius-min);
    padding: var(--spacing-xxs) var(--spacing-xs);
    margin: 0 var(--spacing-xs) var(--spacing-xs) 0;
  }
}

@media (max-width: $breakpoint-m) {
  .transcripts-name {
    @include font-sans-xl-strong;
    gap: var(--spacing-s);

    svg {
      width: 3.5rem;
      height: 1.7rem;
    }
  }

  .transcripts-intro {
    @include font-sans-s-semi;
  }
}

@media (max-width: $breakpoint-s) {
  .transcripts-content .transcript-metadata {
    gap: 0;
  }

  .transcripts-search {
    margin-block: var(--spacing-m);
    gap: var(--spacing-m);
  }

  .transcripts-filters__container {
    align-items: center;

    .dropdown__button {
      display: flex;
      align-items: center;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .transcripts {
    padding: var(--spacing-l);
  }

  .transcripts-intro {
    margin-bottom: var(--spacing-xl);
    margin-top: var(--spacing-l);
  }

  .transcript-metadata__title {
    @include font-sans-m-strong;
    margin: 0;
  }

  .transcripts-filters,
  .transcript-filters-container .form__input[type=text],
  .transcript-filters-container .form__input[type=text]::placeholder,
  .transcript-paragraph__empty-text {
      @include font-sans-s-default;
  }

  .transcripts-filters {
    flex-direction: column;
    gap: 0;
  }

  .transcripts-filters__sentence {
    display: none;
  }

  .transcripts-filters__link {
    @include font-interface-s-semi;
  }

  .transcript-paragraph__empty-text {
    padding-left: 0;
  }
}

/* Modal */

.transcripts-modal__container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(7, 0, 46, 0.3);
}

.transcripts-modal {
  background-color: none;
  width: 896px;
  max-height: 621px;
  max-width: 960px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  box-shadow: 0 0 var(--spacing-l) 0 #07002E30;
  border-radius: var(--radius-popin);
  margin: var(--spacing-xl);
}

.transcripts-modal__feature {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--spacing-xl);
  padding: var(--spacing-xxl);
  background: var(--color-feedback-info-surface-contrast-darker);
  border-top-left-radius: var(--radius-popin);
  border-bottom-left-radius: var(--radius-popin);
}

.transcripts-modal__title,
.transcripts-modal__description,
.transcripts-modal__footer {
  color: var(--color-feedback-info-text-shape-contrast-inverted);
}

.transcripts-modal__title {
  @include font-sans-3xl-strong;

  svg {
    width: 4.7rem;
    height: 2rem;
  }
}

.transcripts-modal__cta {
  display: flex;
  gap: var(--spacing-xl);
  margin-top: var(--spacing-xl);
  margin-bottom: var(--spacing-m);

  a {
    text-decoration: none;
    cursor: pointer;
  }
}

.transcripts-modal__footer {
  @include font-sans-s-default;
}

.transcripts-modal__illustration img {
  border-top-right-radius: var(--radius-popin);
  border-bottom-right-radius: var(--radius-popin);
}

@media (max-width: $breakpoint-s) {
  .transcripts-modal {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    width: 467px;
    height: 467px;
    max-width: 467px;
  }

  .transcripts-modal__feature {
    order: 2;
    gap: var(--spacing-s);
    border-top-left-radius: 0;
    border-bottom-left-radius: var(--radius-popin);
    border-bottom-right-radius: var(--radius-popin);
  }

  .transcripts-modal__title {
    @include font-sans-xl-strong;

    svg {
      width: 3.7rem;
      height: 1.5rem;
    }
  }

  .transcripts-modal__description {
    @include font-sans-s-default;
  }

  .transcripts-modal__footer {
    @include font-sans-xs-default;
  }

  .transcripts-modal__illustration {
    order: 1;
    overflow: hidden;
    border-top-left-radius: var(--radius-popin);
    border-top-right-radius: var(--radius-popin);

    img {
      width: 100%;
      height: auto;
      transform: translateY(-50%);
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .transcripts-modal__feature {
    padding: var(--spacing-xl);
  }
}
