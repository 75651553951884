.sidenav {
  display: flex;
  flex-direction: column;
  gap: 0;
  padding: 0 var(--spacing-xs);
}

.sidenav__link {
  @include font-interface-s-semi;
  line-height: 1.3; /* Locally overriden in design */
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: var(--spacing-s) var(--spacing-xs);
  border-radius: var(--radius-min);
  background-color: transparent;
}

.sidenav__link.-active {
  color: var(--color-global-state-hover-text-shape-default);
  background-color: var(--color-global-state-hover-surface-light);
}

.sidenav__icon {
  fill: currentColor;
  margin-right: var(--spacing-xs);
  position: relative;
  top: 0.1rem;
}

.sidenav__link, .sidenav__link.-active {
  &:hover,
  &:focus-visible {
    background-color: var(--color-global-state-hover-surface-default);
    color: var(--color-global-state-hover-text-shape-inverted);

    .sidenav__icon {
      fill: var(--color-global-state-hover-text-shape-inverted);
    }
  }
}

@media (max-width: $breakpoint-m) {
  .sidenav {
    padding: 0;
  }
}

@media (max-width: $breakpoint-s) {
  .sidenav__link {
    font-size: 1.1rem;
    padding: var(--spacing-xs);
  }

  .sidenav__link::before {
    /* Side dot that indicate the active link in the sidebar */
    content: "";
    display: inline-block;
    width: .4rem;
    height: .4rem;
    border-radius: .2rem;
    margin-right: var(--spacing-xxs);
  }

  .sidenav__link.-active::before  {
    background-color: currentColor;
  }

  .sidenav__icon {
    display: none;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .sidenav {
    padding: 0;
    gap: var(--spacing-l);
  }

  .sidenav__link {
    @include font-interface-s-semi;
    padding: var(--spacing-m);
    border-radius: var(--radius-mi);
    border: 1px solid var(--color-neutral-border-light);
  }

  .sidenav__link::before {
    display: none;
  }
}
