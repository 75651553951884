.layout-header {
  display: flex;
  width: 100%;
  background-color: var(--color-global-background-lighter);
  border-radius: var(--radius-none);
  border-bottom: 1px solid var(--color-neutral-border-light);
  height: var(--spacing-48);
  padding: var(--spacing-s) var(--spacing-xl);
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 100;

  &.-homepage {
    height: var(--spacing-88);
  }
}

.layout-header__button-wrap {
  display: flex;
  align-items: center;
  flex: 1;

  &:last-child {
    justify-content: right;
  }

  .layout-header__buttons {
    display: flex;
    align-items: center;
  }

  .button,
  button {
    &.-homepage-button {
      min-height: unset;
      height: 52px;
      width: 52px;
    }

    &:not(.-homepage-button) {
      min-height: unset;
      height: 4rem;
      width: 4rem;

      > svg {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  }
}

.layout-header__button-container {
  display: flex;
  width: 5.6rem;
  height: 5.6rem;
  justify-content: center;
  align-items: center;
}

.layout-header__dropdown {
  display: flex;
  flex-direction: column;
  top: 5.3rem;
  left: 0;
  min-width: 22rem;
  padding: var(--spacing-xs);
  border: 1px solid var(--color-neutral-border-light, #D9D8E1);
  border-radius: var(--radius-rounded);
  background: var(--color-global-background-light);
  box-shadow: 0px 0px var(--spacing-xs, 6px) 0px rgba(7, 0, 46, 0.15);

  &.-homepage {
    top: 6rem;
  }

  label {
    display: flex;
    align-items: center;
    text-align: left;
    color: var(--color-neutral-text-shape-dark);
    padding: var(--spacing-xxs) var(--spacing-l) var(--spacing-xxs) 0;
    border-radius: var(--radius-min);
    cursor: pointer;

    &.-selected svg {
      visibility: visible;
    }

    svg {
      visibility: hidden;
      width: 2.4rem;
      height: 2.4rem;
    }

    &:hover {
      color: var(--color-global-white);
      background: var(--color-global-state-hover-surface-default);
    }
  }

  input {
    border: none;
    background: none;
    max-width: .1rem;
    visibility: hidden;
    padding: 0;
  }
}

.layout-header__logo {
  display: flex;
  align-items: center;
  line-height: 0;
}

.layout-header__img-contexte {
  color: var(--color-neutral-text-shape-default);
  width: 13.7rem;
  height: 2.1rem;

  &:hover {
    color: var(--color-global-state-hover-text-shape-default);
  }

  &.-homepage {
    width: 22.1rem;
    height: 3.3rem;
  }
}

.layout-header__bell {
  position: relative;

  .layout-header__bell-highlight {
    position: absolute;
    top: 1rem;
    left: 2rem;
    display: inline-block;
    height: var(--spacing-s);
    width: var(--spacing-s);
    background-color: var(--color-brand-primary-surface-default);
    border-radius: var(--radius-max);
    border: 1px solid var(--color-neutral-border-lighter);
  }

  &.-homepage-button {
    .layout-header__bell-highlight {
      top: 1.2rem;
      left: 2.8rem;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .layout-header {
    padding: var(--spacing-xxs) .5rem;

    &.-homepage {
      height: var(--spacing-72);
    }
  }

  .layout-header__img-contexte {
    &.-homepage {
      width: 18rem;
      height: 4rem;
    }
  }
}

@media print {
  .layout-header.-printed {
    position: unset;
    padding-inline: var(--spacing-48);

    .layout-header__button-wrap {
      display: none;
    }
  }
}
