/* TODO: remove briefing-logo, .briefing-datepicker, .button.button .briefing-datepicker__icon and briefing-datepicker__time-year when augmented briefing is open to everyone */
/* @TODO: set -dev as generic */

/* LAYOUT */

body:has(.layout-main.-augmented-briefing) {
  overscroll-behavior-y: none;
}

.layout-main.-augmented-briefing {
  grid-template-columns: auto;
  --scroll-padding-top: 12rem;
  --briefing-toc-top-margin: 7rem;

  .panel {
    grid-row: 3;
  }
}

.layout-main:not(.-augmented-briefing) {
  --scroll-padding-top: 7rem;
  --briefing-toc-top-margin: var(--spacing-xxl);

  .briefing {
    margin-top: -0.1rem;
  }
}

.briefing {
  margin-bottom: 3.2rem;
}

/* HEADER */

.briefing-header__container.-scrolled {
  grid-row: 2;
  position: fixed;
  top: var(--spacing-48);
  z-index: 15;
  width: 100%;

  .briefing-header,
  .briefing-nav-sticky-container {
    height: 4.8rem;
  }

  .layout-stage {
    padding-block: var(--spacing-m);
  }

  .main-column {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .briefing-header__title {
    @include font-produkt-xxl;
    margin-top: 0;
    margin-bottom: 0;
  }

  .briefing-header__subtitle {
    display: none;
  }

  form {
    text-align: right;
  }

  .briefing-datepicker__label svg {
    display: none;
  }

  .briefing-nav-sticky-container {
    position: relative;
    z-index: 17;
    background: var(--color-global-state-hover-surface-light);
  }
}

.briefing-header__container:not(.-scrolled) {
  grid-row: 1;

  .briefing-header {
    position: relative;
    z-index: 20;
    padding: var(--spacing-48) 0 var(--spacing-xl);
  }

  .briefing-nav-sticky-container {
    position: relative;
    z-index: 10;
    background: var(--color-global-background-light);

    .main-column {
      background: var(--color-global-background-light);
      padding: var(--spacing-xl) 0 var(--spacing-l);
      border-bottom: 1px solid var(--color-neutral-border-light);
      margin-inline: var(--spacing-xxxl);
    }
  }
}

.briefing-header {
  background-color: var(--color-global-background-paywall-default);
  color: var(--color-neutral-text-shape-contrast-default);
}

.briefing-header__title {
  @include font-produkt-4xl;
  margin-top: -0.5rem;
  margin-bottom: var(--spacing-xs);

  span {
    font-weight: 400;
  }
}

.briefing-header__subtitle {
  @include font-serif-m-default;
  margin-bottom: var(--spacing-l);
}

/* BRIEFING */

.briefing__edition-name {
  font: normal 900 4.2rem/1 var(--font-the-future);
  transition: all .2s ease;
  margin-bottom: var(--spacing-xxxl);
  /* Add extra margin to align first kicker with TOC */
  padding-bottom: var(--spacing-xxxl);
}

.briefing__edition-name,
.briefing .briefitem-newscard {
  /* Workaround of scroll-padding-top: var(--scroll-padding-top); for all browsers */
  padding-top: var(--scroll-padding-top);
  margin-top: calc(-1 * var(--scroll-padding-top));
}

.briefing__prefix {
  text-transform: none;
  color: var(--color-brand-primary-text-shape-default);
}

.briefing__for {
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1.5;
  color: var(--color-neutral-text-shape-light);
}

.briefing__section {
  @include font-sans-xl-strong;
  margin-bottom: var(--spacing-xxxl);
  color: var(--color-neutral-text-shape-light);
  /* Reproduce the behavior of scroll-padding-top: var(--scroll-padding-top); for all browsers */
  padding-top: var(--scroll-padding-top);
  margin-top: calc(-1 * var(--scroll-padding-top));
}

.briefing__intro {
  color: var(--color-neutral-text-shape-light);
  padding-bottom: var(--spacing-xxl);
  margin-bottom: var(--spacing-xxl);
  border-bottom: 1px solid var(--color-neutral-border-light);
}

.briefing__outro {
  @include font-sans-s-default;
  color: var(--color-neutral-text-shape-light);
  margin-bottom: var(--spacing-72);
}

.briefing__intro a:visited,
.briefing__outro a:visited {
  color: var(--color-neutral-text-shape-light)
}

.briefing__intro a:hover,
.briefing__intro a:focus-visible,
.briefing__outro a:hover,
.briefing__outro a:focus-visible {
  color: var(--color-global-state-hover-text-shape-default);
}

.briefing__read-previous {
  padding-top: var(--spacing-xxxl);
}

.briefing__outro + .briefing__read-previous {
  margin-top: calc(-1 * var(--spacing-72));
  margin-bottom: var(--spacing-72);
}

.briefing-toc {
  height: calc(100vh - var(--spacing-48) - var(--briefing-toc-top-margin));
  overflow-y: auto;
  position: sticky;
  top: calc(var(--spacing-48) + var(--briefing-toc-top-margin));
  transition: opacity 0.2s, tranform 0.2s;
}

.briefing-logo {
  font: normal 700 4.2rem/1 var(--font-produkt);
  color: var(--color-brand-primary-text-shape-default, #C91616);
  font-feature-settings: 'liga' off;
  letter-spacing: 0.042rem;
  margin-left: 1.7rem;
  transition: all .2s ease;
}

.briefing-logo a,
.briefing-logo a:hover {
  color: inherit;
  text-decoration: none;
}

.briefing__agenda p {
  margin-bottom: var(--spacing-m);
}

/* Datepicker */

.briefing-datepicker {
  margin-left: 1.7rem;
  margin-top: var(--spacing-l);
  margin-bottom: var(--spacing-xxl);
}

.button.button .briefing-datepicker__icon {
  width: 1.6rem;
  height: 1.6rem;
  margin-top: var(--spacing-xxxxs);
}

.briefing-datepicker__label {
  position: relative;

  &.-dev {
    color: var(--color-neutral-text-shape-contrast-default) !important;
  }
}

.briefing-datepicker__input {
  position: absolute;
  bottom: 0;
  opacity: 0;
  width: 0;
  height: 0;
  border: none;
  padding: 0;
  margin-right: -0.4rem;
}

/* Briefing TOC */

.briefing-toc__brief {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-s);
}

.briefing-toc__brief.-subscribed + .briefing-toc__brief:not(.-subscribed)::before {
  content: "";
  display: block;
  height: 1px;
  background: var(--color-neutral-border-light);
  margin-bottom: var(--spacing-xxl);
  margin-left: 1.7rem;
}

a.briefing-toc__brief-name {
  font: normal 900 2rem/1 var(--font-the-future);
  display: flex;
  align-items: center;
  padding-left: 1.7rem;
  min-height: 3.2rem;
  text-decoration: none;
  border-radius: var(--radius-min);
  /* Fake text-edge: cap; */
  padding-bottom: var(--spacing-xxxs);

  &:hover {
    color: var(--color-global-state-hover-text-shape-inverted);
    background: var(--color-global-state-hover-surface-default);
  }

  &:active {
    color: var(--color-global-state-hover-text-shape-inverted);
    background: var(--color-global-state-hover-surface-dark);
  }
}

a.briefing-toc__link {
  font: normal 600 1.4rem/1.3 var(--font-graphik);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: var(--spacing-xs);
  gap: var(--spacing-xs);
  border-radius: var(--radius-min);
  min-height: 3.2rem;

  &:last-of-type {
    margin-bottom: var(--spacing-l);
  }

  &::before {
    content: "";
    display: inline-block;
    align-self: flex-start;
    margin-top: var(--spacing-s);
    flex-shrink: 0;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: var(--radius-max);
    background: transparent;
  }

  &:hover {
    color: var(--color-global-state-hover-text-shape-inverted);
    background: var(--color-global-state-hover-surface-default);

    &::before {
      background: var(--color-global-state-hover-text-shape-inverted);
    }
  }

  &:active {
    color: var(--color-global-state-hover-text-shape-inverted);
    background: var(--color-global-state-hover-surface-dark);

    &::before {
      background: var(--color-global-state-hover-text-shape-inverted);
    }
  }

  &.-current:not(:hover, :active) {
    color: var(--color-global-state-hover-text-shape-default);

    &::before {
      background: var(--color-global-state-hover-text-shape-default);
    }
  }
}

.briefing-toc__brief-name + .briefing-toc__link {
  margin-top: var(--spacing-xxs);
}

.briefing-toc__brief a.briefing-toc__link {
  display: none;
}

.briefing-toc__brief.-current a.briefing-toc__link {
  display: flex;
}

.briefing .briefitem-newscard {
  margin-bottom: var(--spacing-xxxl);

  &:not(:has(.briefitem-newscard__body)) {
    margin-bottom: var(--spacing-xl);
  }
}

/* Briefing Nav [S-Mobile] */

.briefing-nav-container {
  position: relative;
  display: none;
  margin-bottom: -10rem;
  z-index: 1;
  background-color: var(--color-global-background-light);
}

.briefing-nav-sticky-container:not(.-dev) {
  display: none;
  padding: var(--spacing-xs) 0 var(--spacing-l);
  border-bottom: 1px solid var(--color-neutral-border-light);
  background: var(--color-global-background-lighter);
  position: fixed;
  top: var(--spacing-48);
  left: 0;
  right: 0;
  z-index: 300;
}

.briefing-nav-sticky-container.-dev {
  display: none;
}

.briefing-nav__logo {
  @include font-edition-xxl;
  display: inline-flex;
  padding-top: var(--spacing-xs);
  gap: var(--spacing-s);
  align-items: center;
  user-select: none;

  &.-dev {
    @include font-edition-xl;
    padding-top: 0;
  }

  &:hover {
    cursor: pointer;

    .briefing-nav__button {
      border: 1px solid var(--color-global-state-hover-border-default);
      background: var(--color-global-state-hover-surface-default);
      color: var(--color-global-white);
    }
  }
}

.briefing-nav__logo-title {
  @include font-produkt-xxl;
  color: var(--color-brand-primary-text-shape-default);
}

.briefing-nav__button {
  margin-top: var(--spacing-xxs);
}

.briefing-datepicker.-mobile {
  margin: 0 0 0 -0.5rem;
}

.briefing-nav__dropdown {
  display: inline-block;
}

.briefing-nav__menu {
  border-radius: var(--radius-rounded);
  border: 1px solid var(--color-neutral-border-light);
  background: var(--color-global-background-lighter);
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.10);
  display: flex;
  padding: var(--spacing-xs);
  flex-direction: column;
  align-items: stretch;
  top: 100%;
  margin-top: var(--spacing-l);
  right: -7.2rem;
}

.briefing-nav__menu-item {
  min-height: 4rem;
  padding: var(--spacing-s) var(--spacing-xl);
  border-radius: var(--radius-min);
  @include font-sans-s-default;
  text-decoration: none;

  &:hover {
    color: var(--color-global-state-hover-text-shape-inverted);
    background: var(--color-global-state-hover-surface-default, #2159D3);
  }
}

/* Responsive */

@media screen and (max-width: $breakpoint-m) {

  /* LAYOUT */

  .layout-main.-augmented-briefing {
    --scroll-padding-top: 11rem;
    --briefing-toc-top-margin: 6rem;
  }

  .layout-main:not(.-augmented-briefing) {
    --briefing-toc-top-margin: var(--spacing-xl);
  }

  /* BRIEFING */

  .briefing-logo,
  .briefing__edition-name {
    font-size: 2.4rem;
  }

  .briefing__edition-name {
    padding-bottom: 0;
  }

  .briefing-toc {
    font-size: 1.35rem;
    line-height: 1.8rem;
  }

  .briefing-datepicker__time-year {
    display: none;
  }

  a.briefing-toc__brief-name {
    @include font-edition-s;
    min-height: unset;
  }

  a.briefing-toc__link {
    @include font-interface-xxs-semi;
    padding: var(--spacing-s) var(--spacing-xs);
    min-height: unset;

    &::before {
      margin-top: 0.3rem;
    }
  }

  .briefing .briefitem-newscard {
    margin-bottom: var(--spacing-xl);
  }

  .briefing__section {
    @include font-sans-l-strong;
    margin-top: calc(var(--spacing-xxl) + var(--scroll-padding-top) * -1);
    margin-bottom: var(--spacing-xl);
  }
}

@media screen and (max-width: $breakpoint-s) {

  /* LAYOUT */

  .layout-main.-augmented-briefing {
    --scroll-padding-top: 8rem;
    --briefing-toc-top-margin: 6rem;

    .briefing__edition-name {
      margin-top: calc(-1 * 16.4rem);
    }
  }

  /* BRIEFING HEADER */

  .briefing-header__container:not(.-scrolled) {
    .briefing-header {
      padding-top: var(--spacing-xxxl);
      padding-bottom: var(--spacing-s);
    }
  }

  .briefing-header__container.-scrolled {
    .briefing-header__title {
      @include font-produkt-xl;
    }

    .briefing-nav-sticky-container .main-column {
      margin-inline: 0;
    }
  }

  /* BRIEFING TOC */

  .briefing-toc {
    display: none;
  }

  .briefing-datepicker__time-year {
    display: unset;
  }

  /* BRIEFING NAV */

  .briefing-nav-sticky-container,
  .briefing-nav-sticky-container.-dev {
    display: block;
  }

  .briefing-nav-container {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    row-gap: var(--spacing-l);
  }

  .briefing-nav__dropdown-wrapper {
    flex-grow: 1;
  }

  .briefing-datepicker {
    flex-shrink: 0;
  }

  /* BRIEFING */

  .briefing__intro.-dev {
    margin-top: var(--spacing-48);
  }

  .briefing__section.-dev {
    margin-top: calc(var(--spacing-xxl) + var(--scroll-padding-top) * -1);
  }
}

@media screen and (max-width: $breakpoint-mobile) {

    /* LAYOUT */

    .layout-main.-augmented-briefing {
      --scroll-padding-top: 8rem;
    }

    /* HEADER */

  .briefing-header__container:not(.-scrolled) {
    .briefing-header {
      padding-top: var(--spacing-s);
      padding-bottom: 0;
    }

    .briefing-nav-sticky-container .main-column {
      margin-inline: var(--spacing-xl);
    }

    .briefing-header__title {
      @include font-produkt-3xl;
    }

    .briefing-header__subtitle {
      @include font-serif-s-medium;
    }
  }
}

@media print {
  .briefing {
    --scroll-padding-top: 0;
  }

  .briefing-header__container.-scrolled {
    display: none;
  }

  .briefing-header__container:not(.-scrolled) .briefing-header {
    background-color: var(--color-global-background-light);
    padding-top: var(--spacing-l);
    padding-bottom: 0;
  }

  .briefing-header {
    color: var(--color-neutral-text-shape-default);
  }

  .briefing__edition-name {
    margin-top: var(--scroll-padding-top);
    margin-bottom: 0;
  }

  .briefing-toc {
    display: none;
  }

  .briefing__read-previous {
    display: none;
  }
}
