.briefitem-wrapper {
  margin-bottom: var(--spacing-xxxxl);
}

.briefitem-header {
  display: flex;
  flex-direction: column;
}

.briefitem-briefing-logo {
  color: var(--color-brand-primary-text-shape-default);
  @include font-produkt-xxl;
}

.briefitem-briefing-name {
  color: var(--color-neutral-text-shape-default);
  font: normal 900 2.4rem/1.5 var(--font-the-future);
  @include font-edition-xxl;
  margin-left: var(--spacing-s);
}

.briefitem-kicker {
  @include font-sans-xxl-strong;
  color: var(--color-neutral-text-shape-light);
  margin-top: var(--spacing-xxxl);
}

.briefitem-header__heading {
  margin-top: var(--spacing-xxxl);

  .briefitem-kicker + & {
    margin-top: var(--spacing-xl);
  }
}

.briefitem-header__geography {
  color: var(--color-brand-primary-text-shape-default);
  @include font-condensed-xxl-strong;
}

.briefitem-header__title {
  margin-left: 0.3rem;
  @include font-sans-xxl-strong;
  color: var(--color-neutral-text-shape-default);
}

.briefitem-body {
  margin-top: var(--spacing-s);
  color: var(--color-neutral-text-shape-default);
  @include font-sans-l-default;

  a {
    &:hover,
    &:focus-visible {
      color: var(--color-global-state-hover-text-shape-default);
    }
  }
}

.briefitem-source {
  @include font-sans-s-default;
  margin: var(--spacing-xxl) 0;
  color: var(--color-neutral-text-shape-light);

  a {
    @include font-sans-s-link;

    &:hover {
      color: var(--color-global-state-hover-text-shape-default);
    }
  }
}

.briefitem-footer {
  padding: var(--spacing-xl) 0;
  border-top: 1px solid var(--color-neutral-border-light);
}

.briefitem-footer__tags {
  display: flex;
  align-items: center;
  gap: var(--spacing-s);
  flex-wrap: wrap;
}

@media (max-width: $breakpoint-m) {
  .briefitem-wrapper {
    margin-bottom: var(--spacing-xxxl);
  }

  .briefitem-kicker {
    @include font-sans-xl-strong;
    margin-top: var(--spacing-xl);
  }

  .briefitem-header__heading {
    margin-top: var(--spacing-xl);

    .briefitem-kicker + & {
      margin-top: var(--spacing-s);
    }
  }

  .briefitem-header__title {
    @include font-sans-xl-strong;
  }

  .briefitem-header__geography {
    @include font-condensed-xl-strong;
  }

  .briefitem-body {
    margin-top: 0;
  }

  .briefitem-source {
    margin: var(--spacing-xl) 0;
  }
}

@media print {
  .briefitem-footer {
    display: none;
  }
}
