.paywall {
  z-index: 99;
  position: fixed;
  left: 0;
  right: 0;
  top: calc(100vh - 4.8rem);
  transition: background-color .3s ease;

  &.-active {
    overflow-y: scroll;
    height: 100svh;
    top: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
  }
}

.paywall__container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-global-background-paywall-lighter);
  box-shadow: 0 0 8rem rgba(0, 0, 0, 0.3);
  padding: 0 0 var(--spacing-xxxl);
  color: var(--color-neutral-text-shape-dark);
}

.paywall__trial {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-xxl);
  text-align: center;

  &.-top {
    margin-top: -18rem;
    background-color: var(--color-global-background-paywall-lighter);
    padding: var(--spacing-xxxl) var(--spacing-xxl) 0;
  }
}

.paywall__title {
  @include font-serif-3xl-strong;
  text-align: center;
}

.paywall__statement {
  margin: var(--spacing-xl) 0 var(--spacing-xxl);
  @include font-sans-l-default;
}

.paywall__contents {
  max-width: 72.3rem;
  padding: var(--spacing-xxl) 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-xxl);
}

.paywall__login-banner {
  width: 100%;
  text-align: center;
  background-color: var(--color-brand-abo-surface-default);
  padding: var(--spacing-l) var(--spacing-xxl);
  color: var(--color-brand-abo-text-shape-contrast-darker);
  @include font-sans-m-default;
}

.paywall__login {
  @include font-sans-m-strong;
}

.paywall__form {
  min-width: 50%;

  fieldset {
    border: none;
    margin: 0 0 var(--spacing-xxs);
    padding: 0;
  }

  .paywall__helper-link {
    @include font-interface-s-default;
    text-decoration: none;
  }
}

.paywall__form-submit {
  text-align: center;
  margin: var(--spacing-xl) 0 0;
}

@media screen and (max-width: $breakpoint-m) {
  .paywall__trial.-top {
    padding: var(--spacing-xl) var(--spacing-xl) 0;
  }

  .paywall__contents {
    gap: var(--spacing-xl)
  }
}

@media screen and (max-width: $breakpoint-s) {
  .paywall__trial.-top {
    margin-top: 0;
    padding: 0;
  }

  .paywall__title {
    @include font-serif-xxl-strong;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .paywall__contents {
    padding: var(--spacing-xxl);
  }

  .paywall__form {
    width: 100%;
  }
}
