@mixin button-size-s {
  min-height: 4rem;
  min-width: 3.2rem;
  padding: calc(var(--spacing-xs) - 0.1rem) calc(var(--spacing-m) + var(--spacing-xs));
  gap: calc(var(--spacing-xs) * 2);

  svg {
    width: 1.8rem;
    height: 1.8rem;
    margin: 0 calc(0rem - var(--spacing-xs));
  }

  &.-square {
      width: 4rem;
      height: 4rem;
      min-width: unset;
      min-height: unset;
      padding: 0;
  }
}

@mixin button-size-xxs {
  min-height: 2.4rem;
  padding: calc(var(--spacing-xxs) - 0.1rem) calc(var(--spacing-xs) + var(--spacing-xxs));
  gap: calc(var(--spacing-xxs) * 2);

  svg {
      width: 1.4rem;
      height: 1.4rem;
      margin: 0 calc(0rem - var(--spacing-xxs));
  }

  &.-square {
      width: 2.4rem;
      min-height: unset;
      height: 2.4rem;
      padding: 0;
  }
}

@mixin button-hover-active {
  &:not([disabled], .-disabled) {

    &,
    &.-contrasted,
    &.-inverted,
    &.-inverted.-contrasted {

      &:hover,
      &:focus-visible {
        border: 1px solid transparent;
        background: var(--color-global-state-hover-surface-default);
        color: var(--color-global-white);
      }

      &:active {
        border: 1px solid transparent;
        background: var(--color-global-state-hover-surface-dark);
        color: var(--color-global-white);
      }
    }
  }
}

@mixin button-hover-success {
  svg {
    fill: var(--color-neutral-surface-contrast-default);
  }

  &.-inverted {
    &:hover {
      border: 1px solid var(--color-feedback-success-border-default);
      background: var(--color-feedback-success-surface-default);

      svg {
        fill: var(--color-neutral-surface-contrast-light);
      }
    }
  }
}

@mixin button-level-transparency {
  &,
  &.-primary {
    border: 1px solid transparent;
  }

  &.-secondary,
  &.-tertiary {
    background: transparent;

    &.-contrasted{
      background: transparent;
      color: var(--color-neutral-text-shape-contrast-default);
    }

    &.-inverted {
      background: transparent;

      &.-contrasted{
        background: transparent;
      }
    }
  }
  &.-tertiary,
  &.-contrast,
  &.-inverted,
  &.-inverted.-contrasted{
    border: 1px solid transparent;
  }
}

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 0;
  border-radius: var(--radius-min);
  text-decoration: none;
  cursor: pointer;

  svg {
    flex-shrink: 0;
  }

  /* States */

  &,
  &.-primary,
  &.-secondary,
  &.-tertiary {
    &:hover,
    &:focus-visible,
    &.-disabled,
    &[disabled] {
      text-decoration: none;
    }

    @include button-hover-active;
    @include button-level-transparency;

    &.-disabled,
    &[disabled] {
      cursor: default;
      border: 1px solid transparent;
      background: var(--color-global-state-disabled-surface-default);
      color: var(--color-global-state-disabled-text-shape-default);
      text-decoration: none !important;
      cursor: not-allowed;

      &.-inverted {
        border: 1px solid var(--color-border-inverted);
        background: var(--color-global-state-disabled-surface-inverted);
      }
    }
  }


  /* Levels */
  &,
  &.-primary {
    background: var(--color-neutral-surface-dark);
    color: var(--color-neutral-text-shape-inverted);

    &.-contrasted{
      border: 1px solid var(--color-neutral-border-contrast-lighter);
      background: var(--color-neutral-surface-contrast-dark);
      color: var(--color-neutral-text-shape-contrast-default);
    }

    &.-inverted {
      border: 1px solid var(--color-neutral-border-lighter);
      background: var(--color-neutral-surface-light);
      color: var(--color-neutral-text-shape-dark);

      &.-contrasted {
        border: 1px solid var(--color-neutral-border-contrast-darker);
        background: var(--color-neutral-surface-contrast-light);
        color: var(--color-neutral-text-shape-contrast-lighter);
      }
    }
  }

  &.-secondary,
  &.-tertiary {
    color: var(--color-neutral-text-shape-default);

    &.-contrasted{
      color: var(--color-neutral-text-shape-contrast-lighter);
    }

    &.-inverted {
      color: var(--color-neutral-text-shape-inverted);

      &.-contrasted{
        color: var(--color-neutral-text-shape-contrast-default);
      }
    }
  }

  &.-secondary {
    border: 1px solid var(--color-neutral-border-light);

    &.-contrasted {
      border: 1px solid var(--color-neutral-border-contrast-dark);
    }

    &.-inverted {
      border: 1px solid var(--color-neutral-border-dark);

      &.-contrasted {
        border: 1px solid var(--color-neutral-border-contrast-default);
      }
    }
  }

  /* Sizes */

  &.-size-xl {
    min-height: 5.6rem;
    padding: var(--spacing-l) calc(var(--spacing-l)*2);
    gap: calc(var(--spacing-l) * 2);
    @include font-interface-xl-semi;

    svg {
      width: 3.2rem;
      height: 3.2rem;
      margin: 0 calc(0rem - var(--spacing-l));
    }

    &.-square {
      width: 5.6rem;
      min-height: unset;
      height: 5.6rem;
      padding: 0;
    }
  }

  &,
  &.-size-m {
    min-height: 4.8rem;
    padding: calc(var(--spacing-l) - 0.1rem) calc(var(--spacing-m) + var(--spacing-s));
    gap: calc(var(--spacing-s) * 2);
    @include font-interface-m-semi;

    svg {
      width: 2.4rem;
      height: 2.4rem;
      margin: 0 calc(0rem - var(--spacing-s));
    }

    &.-square {
      width: 4.8rem;
      min-height: unset;
      height: 4.8rem;
      padding: 0;
    }
  }

  &.-size-s {
    @include button-size-s;
    @include font-interface-s-semi;
  }

  &.-size-xxs {
    @include button-size-xxs;
    @include font-interface-xxs-semi;
  }

  /* Rounded */

  &.-rounded {
    border-radius: var(--radius-max);

    &.-square {
      align-items: center;
    }
  }

  /* Skins */

  &.-destructive:not([disabled], .-disabled) {
    @include button-hover-active;
    @include button-level-transparency;

    &,
    &.-primary {
      border: 1px solid transparent;
      background: var(--color-feedback-danger-surface-default);
      color: var(--color-global-white);
    }

    &.-secondary {
      border: 1px solid var(--color-feedback-danger-border-default);
    }

    &.-secondary,
    &.-tertiary {
      color: var(--color-feedback-danger-text-shape-default);
    }
  }

  &.-abo:not([disabled], .-disabled) {
    @include button-hover-active;
    @include button-level-transparency;

    &,
    &.-primary {
      border: 1px solid transparent;
      background: var(--color-brand-abo-surface-default);
      color: var(--color-brand-abo-text-shape-contrast-darker);

      &.-contrasted {
        border: 1px solid var(--color-brand-abo-border-contrast-default);
        background: var(--color-brand-abo-surface-contrast-default);
      }
    }

    &.-secondary {
      border: 1px solid var(--color-brand-abo-border-default);

      &.-contrasted {
        border: 1px solid var(--color-brand-abo-border-contrast-default);
      }
    }

    &.-secondary,
    &.-tertiary {
      color: var(--color-brand-abo-text-shape-darker);

      &.-contrasted {
        color: var(--color-brand-abo-text-shape-contrast-default);
      }
    }
  }

  &.-success {
    &.-primary {
      @include button-hover-success;
    }
  }
}

/* Responsive */

@media (max-width: $breakpoint-m) {
  .button {
    &.-size-s-below-breakpoint-m {
      @include button-size-s;
      @include font-interface-s-semi;
    }

    &.-size-xxs-below-breakpoint-m {
      @include button-size-xxs;
      @include font-interface-xxs-semi;
    }
  }
}
