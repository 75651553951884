@import "tippy.js/dist/tippy.css";
@import "tippy.js/animations/shift-toward.css";

// Forked from light theme
// Available at @import "tippy.js/themes/light.css";


.tippy-box[data-theme~=disco] {
  color: #26323d;
  background-color: #fff;
  box-shadow: 0 0 20px 4px rgba(154, 161, 177, .05),
    0 4px 80px -8px rgba(36, 40, 47, .083),
    0 4px 4px -2px rgba(91, 94, 105, .05);
  border: 1px solid var(--color-neutral-border-lighter);
}

.tippy-box[data-theme~=disco] .tippy-content {
  padding: var(--spacing-s) var(--spacing-l);
}

.tippy-box[data-theme~=disco][data-placement^=top]>.tippy-arrow:before {
  border-top-color: #fff
}

.tippy-box[data-theme~=disco][data-placement^=bottom]>.tippy-arrow:before {
  border-bottom-color: #fff
}

.tippy-box[data-theme~=disco][data-placement^=left]>.tippy-arrow:before {
  border-left-color: #fff
}

.tippy-box[data-theme~=disco][data-placement^=right]>.tippy-arrow:before {
  border-right-color: #fff
}

.tippy-box[data-theme~=disco]>.tippy-backdrop {
  background-color: #fff
}

.tippy-box[data-theme~=disco]>.tippy-svg-arrow {
  fill: #fff
}
