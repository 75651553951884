.preview-block {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-xl);
  gap: var(--spacing-l);
  background-color: var(--color-neutral-surface-lighter);
  border: 1px solid var(--color-neutral-border-light);
  border-radius: 0.6rem;
  position: relative;
  top: calc(var(--js-link-top) + 1.5rem);
  transform: translateY(-50%);
}

@media screen and (max-width: $breakpoint-m) {
  .preview-block {
    font-size: 1rem;
  }
}

.preview-block.-no-border {
  background-color: transparent;
  border: none;
}

@media screen and (max-width: $breakpoint-mobile) {
  .preview-block {
    pointer-events: unset;
    position: fixed;
    width: 100%;
    top: unset;
    bottom: 0;
    left: 0;
    right: 0;
    transform: none;
    max-height: 33dvh;
    overflow-y: auto;
    box-shadow: 0 -0.5rem 8px rgba(0, 0, 0, 0.25);
    border: 0 none;
  }

  .preview-block.-no-border {
    background-color: var(--color-neutral-surface-lighter);
  }
}

.preview-block__doc {
  background-color: var(--color-neutral-border-dark);
  padding: 0.8rem 0;
  display: flex;
  justify-content: center;
}

.preview-block__title {
  @include font-sans-s-semi;
  overflow-wrap: break-word;
}

.preview-block__editorial-nature {
  @include font-condensed-s-strong;
  color: var(--color-brand-primary-text-shape-default);
}

.preview-block__subtitle {
  @include font-sans-xs-default;
  color: var(--color-neutral-text-shape-default);
}

.preview-block__footer {
  @include font-sans-xs-default;
  color: var(--color-neutral-text-shape-light);
}

.preview-block__bill {
  font-size: 1.3rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #09a7f9;
  margin-right: 0.8rem;
}

.preview-block__bill-watch,
.preview-block__briefitem-summary {
  border-radius: var(--radius-min);
  color: var(--color-neutral-text-shape-default);

  &:has(.watch__icon) {
    border: 1px solid var(--color-neutral-border-light);
  }
}

.preview-block__bill-watch {
  margin-top: 0.3rem;
  margin-left: var(--spacing-s);
  float: right;
  padding: var(--spacing-xs) var(--spacing-m);

  @include font-interface-s-semi;

  .watch__icon {
    margin-right: var(--spacing-s);
  }
}

.preview-block__briefitem-summary {
  display: flex;
  width: 7.2rem;
  height: 2.4rem;
  padding: var(--spacing-s);
  @include font-interface-xxs-semi;

  &:has(.watch__icon) span {
    margin-bottom: var(--spacing-xxxs);
  }

  .watch__icon {
    margin-right: var(--spacing-xxs);
  }

  &.-watched {
    &:hover {
      .watch__label.-watched {
        display: unset !important;
      }

      .watch__icon {
        stroke: var(--color-global-white);
        fill: var(--color-global-white) !important;
      }
    }
  }

  &:not(.-watched) {
    stroke: var(--color-global-white);
  }

  &:hover {
    background-color: var(--color-global-state-hover-surface-default);
    color: var(--color-global-state-hover-text-shape-inverted);

    .watch__label.-hover.-watched {
      display: none !important;
    }

    &:not(.-watched) .watch__icon {
      stroke: var(--color-global-white);
      fill: transparent;
    }
  }
}

.preview-block__external {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.preview-block__external:not(:last-of-type) {
  padding-bottom: 0.3rem;
}

.preview-block__domain {
  @include font-sans-s-semi;
  flex-grow: 1;
  color: var(--color-feedback-info-text-shape-default);
  direction: rtl;
  text-align: left;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.preview-block__favicon {
  flex-shrink: 0;
  border: 1px solid var(--color-neutral-border-lighter);
  height: 1.8rem;
  width: 1.8rem;
  padding: 0.2rem;
}

.preview-block__icon-external {
  flex-shrink: 0;
}

.preview-block__snippet {
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1.5;
  margin-top: 0.4rem;
  overflow-wrap: break-word;
}

.preview-block__geo {
  @include font-condensed-s-strong;
  color: var(--color-brand-primary-text-shape-default);
  margin-right: 0.25rem;
}

.tippy-box .preview-block__image,
.tippy-box .preview-block__doc {
  margin: auto;
  min-width: auto;
  width: 100%;
  border-radius: var(--radius-min);
  object-fit: cover;
  margin-bottom: var(--spacing-xs);
}

.preview-block__image,
.preview-block__doc {
  border-top-left-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
  margin: calc(var(--spacing-xl) * -1) calc(var(--spacing-xl) * -1) 0 calc(var(--spacing-xl) * -1);
  min-width: calc(100% + 3.2rem);
}

@media screen and (max-width: $breakpoint-mobile) {
  .preview-block__image {
    height: auto;
    max-height: 16vh;
    object-fit: cover;
  }

  .preview-block__editorial-nature {
    @include font-condensed-xs-strong;
  }
}
